import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="py-5 ticket-listing">
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
      </Helmet>
      <div className="marquee mb-5">
        <div>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
          <span className="mr-5">{'Tickets.'}</span>
        </div>
      </div>
      <Container className="list-page">
        {/* <img
          src={require('./assets/images/screen_1632826014.jpeg')}
          alt="PARCELS"
          title="PARCELS"
          className="img-fluid"
          loading="lazy"
        /> */}
        {/* eslint-disable-next-line react/jsx-no-literals */}
        {/* <p className="lead my-5 text-center">
          We’re so excited to announce our headline tour for 2022. It’s been a long two years since we’ve been able to
          play for crowds. Since then, we’ve started from scratch, travelled to our very core, studying and building our
          band back up to deliver a show that feels fresh and full of life. When creating Day/Night, we were imagining
          performing it on stage from the very beginning, arranging it like we would a performance in a theatre. We feel
          honoured to now be able to realise this safely in person with all you beautiful people at last. <br />
          <br />
          {'See you very soon.'}
        </p> */}
        {/* <h1 className="text-center mb-4">{t(`TICKETLISTING.TITLE`)}</h1> */}

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="ticket-listing"
            products={tickets}
            type="list"
            filters="off"
            showLocation
            showCity
            showTitle
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
