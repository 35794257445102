import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InputGroupText from 'reactstrap/lib/InputGroupText';
import Label from 'reactstrap/lib/Label';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Spinner from 'reactstrap/lib/Spinner';

import { requestLogin } from '@ttstr/actions/customer';
import { EMAIL_PATTERN, useActions, useToggle } from '@ttstr/utils';

import PasswordResetModal from '@ttstr/components/Navigation/PasswordResetModal';
import { useForm } from '../../../node_modules/react-hook-form';

interface OwnProps {
  onLoginSuccess?(): void;
  show: boolean;
  closeIcon?: React.ReactNode;
  toggle(): void;
}

type Props = Readonly<OwnProps>;

interface Credentials {
  username: string;
  password: string;
}

const UNCONFIRMED = 'UNCONFIRMED';
const FAILED = 'FAILED';

const UserLoginModal: React.FC<Props> = ({ show, toggle, onLoginSuccess, closeIcon, children }) => {
  const [loginError, setLoginError] = React.useState<string>(null);
  const [showPasswordReset, togglePasswordReset] = useToggle(false);
  const { t } = useTranslation();
  const { requestLogin } = useActions(mapDispatchToProps);
  const { register, handleSubmit, formState, errors } = useForm<Credentials>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { isSubmitting } = formState;
  const usernameErrors = errors.username;
  const usernameHasErrors = Boolean(usernameErrors);
  Boolean(errors.username);
  const passwordErrors = errors.password;
  const passwordHasErrors = Boolean(passwordErrors);

  const toggleModal = React.useMemo(() => (isSubmitting ? null : toggle), [isSubmitting, toggle]);

  async function onSubmit({ username, password }: Credentials) {
    try {
      setLoginError(null);
      await requestLogin(username, password);
      toggle();
      onLoginSuccess && onLoginSuccess();
    } catch (error) {
      if (error.message === UNCONFIRMED) return setLoginError(UNCONFIRMED);
      setLoginError(FAILED);
    }
  }

  const close = closeIcon ? (
    <button className="close" onClick={toggleModal}>
      {closeIcon}
    </button>
  ) : null;

  return (
    <>
      <Modal
        size="sm"
        fade
        backdrop="static"
        isOpen={show}
        toggle={toggleModal}
        autoFocus={false}
        returnFocusAfterClose={false}
        unmountOnClose={true}
      >
        <ModalHeader toggle={toggleModal} close={close}>
          {t(`LOGIN.TITLE`)}
        </ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <fieldset disabled={isSubmitting}>
            <ModalBody>
              {children}
              <FormGroup tag="fieldset">
                <Label for="username" className="sr-only">
                  {t(`LOGIN.USERNAME`)}
                </Label>
                <InputGroup className={classNames({ 'is-invalid': usernameHasErrors })}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fal fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    id="username"
                    name="username"
                    invalid={usernameHasErrors}
                    placeholder={t(`LOGIN.USERNAME`)}
                    autoComplete="username"
                    innerRef={register({
                      required: true,
                      validate: {
                        'STRING.EMAIL': (value) => EMAIL_PATTERN.test(value),
                      },
                    })}
                  />
                </InputGroup>
                {usernameHasErrors && (
                  <FormFeedback>{t(`FORM.VALIDATION.${usernameErrors.type.toUpperCase()}`)}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup tag="fieldset">
                <Label for="password" className="sr-only">
                  {t(`LOGIN.PASSWORD`)}
                </Label>
                <InputGroup className={classNames({ 'is-invalid': passwordHasErrors })}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fal fa-key" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    invalid={passwordHasErrors}
                    placeholder={t(`LOGIN.PASSWORD`)}
                    autoComplete="current-password"
                    innerRef={register({
                      required: true,
                      minLength: 6,
                    })}
                  />
                </InputGroup>
                {passwordHasErrors && (
                  <FormFeedback>
                    {t(`FORM.VALIDATION.${passwordErrors.type.toUpperCase()}`, { minLength: 6 })}
                  </FormFeedback>
                )}
              </FormGroup>
              {loginError && (
                <p className="text-danger">
                  {t(`CUSTOMER.LOGIN.${loginError}`)}
                  {/* {loginError === FAILED && (
                    <Button type="button" onClick={togglePasswordReset} color="link">
                      {t(`CUSTOMER.LOGIN.FORGOT_PASSWORD`)}
                    </Button>
                  )} */}
                </p>
              )}
              <small>
                {t(`LOGIN.NOACCOUNT`)}{' '}
                <strong>
                  <Link to="/customer/register-account" onClick={toggle}>
                    {t(`LOGIN.NOACCOUNTBTN`)}
                  </Link>
                </strong>
              </small>
              <br />
              <small>
                <Link to="#" onClick={togglePasswordReset} color="link">
                  {t(`CUSTOMER.LOGIN.FORGOT_PASSWORD`)}
                </Link>
              </small>
            </ModalBody>
            <ModalFooter className="flex-row-reverse justify-content-between">
              <Button color="primary" type="submit">
                {t(`LOGIN.PROCEED`)}
                <span className={classNames('spinner-wrapper', { invisible: !isSubmitting })}>
                  <Spinner color="primary" size="sm" />
                </span>
              </Button>
              <Button onClick={toggleModal} color="link" type="button">
                {t(`LOGIN.CANCEL`)}
              </Button>
            </ModalFooter>
          </fieldset>
        </Form>
      </Modal>
      <PasswordResetModal isOpen={showPasswordReset} toggle={togglePasswordReset} />
    </>
  );
};

const mapDispatchToProps = {
  requestLogin,
};

export default UserLoginModal;
