import classNames from 'classnames';

import React from 'react';

import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Spinner from 'reactstrap/lib/Spinner';

import {
  Product,
  Variant,
  PackageVariantsMap,
  PackageMembershipGroup,
  PackageMembershipGroupVariant,
} from '@ttstr/api';
import { addPackageToCart, receiveProduct, receiveTickets } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

import { useIntl } from '@ttstr/components';
import { useDate } from '@ttstr/components/Intl/DateComponent';

import RadioList from '../RadioList/RadioList';
import RadioListItem from '../RadioList/RadioListItem';
import { VariantInformation } from './VariantChooser';

interface PackageInformation extends VariantInformation {
  variants_map: PackageVariantsMap;
}

interface OwnProps {
  product: Product;
  isOpen?: boolean;
  cartIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  toggle?: () => void;
  reset?: () => void;
}

type Props = Readonly<OwnProps>;

const PackageModal: React.FC<Props> = ({
  isOpen,
  product,
  cartIcon,
  closeIcon,
  toggle = () => null,
  reset = () => null,
}) => {
  const { t } = useTranslation();
  const { addPackageToCart, receiveProduct } = useActions(mapDispatchToProps);
  const { handleSubmit, formState, watch } = useFormContext<PackageInformation>();
  const { isValid, isSubmitting } = formState;

  const variants: Variant[] = React.useMemo(() => {
    if (Array.isArray(product.online_variants_attributes)) return product.online_variants_attributes;
    return Object.values(product.online_variants_attributes);
  }, [product]);

  const selectedVariantId = Number(watch('variantId'));
  const selectedQuantity = Number(watch('quantity'));

  const selectedVariant = React.useMemo(() => variants.find((v) => v.id === selectedVariantId), [
    variants,
    selectedVariantId,
  ]);

  const selectedMembershipGroups = React.useMemo(() => {
    if (!selectedVariant) return null;
    if (!selectedVariant.package_membership_groups_attributes) return null;

    return Object.values<PackageMembershipGroup>(selectedVariant.package_membership_groups_attributes);
  }, [selectedVariant]);

  const watchAll = watch();

  const somethingHasNotBeenSelected = React.useMemo(() => {
    if (!formState.touched) return true;
    if (!formState.touched.variants_map) return true;

    const groupsYouMustChooseFrom = selectedMembershipGroups?.filter((el) => el.variants_attributes.length > 1).length;
    // check in formStates varian_map if there are as many entrys as there have to be
    const visitedGroupes = Object.entries(formState.touched.variants_map).length;

    return visitedGroupes !== groupsYouMustChooseFrom;
  }, [watchAll]);

  const onPackageSubmit: SubmitHandler<PackageInformation> = async ({ variantId, quantity, variants_map }) => {
    // the default VariantsMap maps each group to its first variant
    const defaultVariantsMap: PackageVariantsMap = selectedMembershipGroups.reduce(
      (previous, current) => ({
        ...previous,
        [current.id]: current.variants_attributes[0].id,
      }),
      {}
    );

    const variantsMapObject = Object.fromEntries(Object.entries(variants_map ?? defaultVariantsMap)); // if nothing was selected the default will be used
    await addPackageToCart(Number(variantId), Number(quantity), variantsMapObject);
    receiveProduct(Number(product.id)); // Update product availability afterwards
    reset();
    toggle();
  };

  const close = closeIcon ? (
    <button className="close" onClick={toggle}>
      {closeIcon}
    </button>
  ) : null;

  return (
    <Modal backdrop="static" toggle={toggle} centered isOpen={isOpen}>
      <Form onSubmit={handleSubmit(onPackageSubmit)}>
        <ModalHeader toggle={toggle} close={close}>
          {t('PRODUCT.CONFIGURE_PACKAGE.TITLE', { count: selectedQuantity })}
        </ModalHeader>
        <ModalBody>
          <p>{t('PRODUCT.CONFIGURE_PACKAGE.DESCRIPTION', { count: selectedQuantity })}</p>
          {selectedQuantity && selectedQuantity > 1 && (
            <Alert color="danger">
              <div
                dangerouslySetInnerHTML={{
                  __html: t('PRODUCT.CONFIGURE_PACKAGE.MULTI_INFORMATION', { count: selectedQuantity }),
                }}
              />
            </Alert>
          )}
          <Card color="light" body className="text-center mb-3">
            <dl className="mb-0">
              <dt>{t('PRODUCT.CONFIGURE_PACKAGE.YOUR_SELECTION')}</dt>
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <dd className="ml-2 mb-0">
                {/* eslint-disable-next-line react/jsx-no-literals */}
                {selectedQuantity} &times; {product.title}
                {selectedVariant?.title ? ` - ${selectedVariant?.title}` : ''}
              </dd>
            </dl>
          </Card>
          {selectedMembershipGroups?.map((group) => (
            <ArticlVariantChooser key={group.id} packageGroup={group} />
          ))}
        </ModalBody>
        <ModalFooter
          className={classNames(
            'flex-row-reverse flex-sm-nowrap justify-content-stretch align-items-stretch sticky-bottom when-valid',
            {
              'is-valid': isValid,
            }
          )}
        >
          <Button
            type="submit"
            className="add-product text-nowrap"
            size="lg"
            color="primary"
            title={t(`PRODUCT.ADD_TO_CART`)}
            disabled={!isValid || isSubmitting}
            block
          >
            {cartIcon}
            <span className="ml-2">{t('PRODUCT.ADD_TO_CART')}</span>
            {isSubmitting && <Spinner color="white" size="sm" className="ml-2" />}
          </Button>
          <Button type="button" onClick={toggle} color="link" block>
            {t('PRODUCT.FORM.CANCEL')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default React.memo(PackageModal);

interface ArticleVariantChooserProps {
  packageGroup: PackageMembershipGroup;
}

const ArticlVariantChooser: React.FC<ArticleVariantChooserProps> = (packageGroup) => {
  const { watch } = useFormContext<PackageInformation>();
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();
  const intlDate = useDate();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  const articleVariants: PackageMembershipGroupVariant[] = React.useMemo(() => {
    if (Array.isArray(packageGroup.packageGroup.variants_attributes)) {
      return packageGroup.packageGroup.variants_attributes;
    }

    return Object.values(packageGroup.packageGroup.variants_attributes);
  }, [packageGroup]);

  const groupedArticleVariants = React.useMemo(() => {
    if (!articleVariants) return null;

    // @ts-ignore
    const indexedVariantArraysByArticleId = articleVariants.reduce(
      (groupedVariants: PackageMembershipGroupVariant[], variant: PackageMembershipGroupVariant) => {
        const article_id = variant.article_id;
        groupedVariants[article_id] = (groupedVariants[article_id] || []).concat(variant);
        return groupedVariants;
      },
      {}
    );

    return Object.values(indexedVariantArraysByArticleId);
  }, [articleVariants]);

  const selectedSubGroup = Number(watch(`subGroup-${packageGroup.packageGroup.id}`));

  return (
    <React.Fragment>
      <div className="text-center text-muted d-flex justify-content-between align-items-center mb-2">
        <span className="h3 mx-3 mb-0">
          <i className="fal fa-angle-double-down" />
        </span>
        <h4 className="m-0 text-alg">{packageGroup.packageGroup.description}</h4>
        <span className="h3 mx-3 mb-0">
          <i className="fal fa-angle-double-down" />
        </span>
      </div>

      <RadioList className="mb-4 packagemodalsubgroup grouped-packagemodalsubgroup">
        {groupedArticleVariants
          .sort((a, b) => a[0]?.valid_start_on - b[0]?.valid_start_on)
          .map((articleArr) => (
            <>
              {articleArr.length === 1 && (
                <RadioListItem
                  key={articleArr[0].article_id}
                  classNames={'kachel-header' + (articleArr[0].remaining_for_online === 0 ? ' disabled' : '')}
                  inputId={`variants_map-${packageGroup.packageGroup.id}-${articleArr[0].id}`}
                  iconUrl={articleArr[0].image.url}
                  title={
                    articleArr[0].valid_start_on && articleArr[0].location && articleArr[0].city
                      ? `${articleArr[0].product_title} - ${intlDate.format(articleArr[0].valid_start_on)}` +
                        (String(articleArr[0].valid_end_on) !== String(articleArr[0].valid_start_on)
                          ? `-${intlDate.format(articleArr[0].valid_end_on)}`
                          : '') +
                        `, ${articleArr[0].location}, ${articleArr[0].city}`
                      : `${articleArr[0].product_title} - ${articleArr[0].product_subtitle}`
                  }
                  name={`variants_map[${packageGroup.packageGroup.id}]`}
                  value={String(articleArr[0].id)}
                  defaultChecked={articleArr.length === 1}
                  disabled={articleArr[0].remaining_for_online === 0}
                />
              )}

              {articleArr.length > 1 && (
                <>
                  <RadioListItem
                    key={articleArr[0].article_id}
                    classNames="kachel-header"
                    inputId={String(articleArr[0].article_id)}
                    iconUrl={articleArr[0].image.url}
                    title={
                      articleArr[0].valid_start_on && articleArr[0].location && articleArr[0].city
                        ? `${articleArr[0].product_title} - ${intlDate.format(articleArr[0].valid_start_on)}` +
                          (String(articleArr[0].valid_end_on) !== String(articleArr[0].valid_start_on)
                            ? `-${intlDate.format(articleArr[0].valid_end_on)}`
                            : '') +
                          `, ${articleArr[0].location}, ${articleArr[0].city}`
                        : `${articleArr[0].product_title} - ${articleArr[0].product_subtitle}`
                    }
                    name={`subGroup-${packageGroup.packageGroup.id}`}
                    value={String(articleArr[0].article_id)}
                    defaultChecked={articleArr.length === 1}
                  />

                  <RadioList className="kachel-container">
                    {/* only show when Header is selected or Article has no variants */}
                    {(selectedSubGroup === articleArr[0].article_id || articleArr.length === 1) &&
                      articleArr.map((variant) => (
                        <RadioListItem
                          key={variant.id}
                          classNames={'kachel'}
                          inputId={`variants_map-${packageGroup.packageGroup.id}-${variant.id}`}
                          iconUrl={variant.image.thumb.url}
                          title={
                            variant.title
                            /**
                             * if there is a ticket in the bundel the full title wont do.
                             * in that case the ptitle of the ticket must do the job.
                             */
                            // tickets.find((t) => t.id === variant.article_id)
                            //   ? tickets
                            //       .find((t) => t.id === variant.article_id)
                            //       .ptitle.toUpperCase()
                            //       .split('-')
                            //       .join(' ')
                            //   : variant.title
                          }
                          name={`variants_map[${packageGroup.packageGroup.id}]`}
                          value={String(variant.id)}
                          disabled={variant.remaining_for_online === 0}
                        />
                      ))}
                  </RadioList>
                </>
              )}
            </>
          ))}
      </RadioList>
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets } = state.Tickets;
  return {
    tickets,
  };
};

const mapDispatchToProps = {
  receiveTickets,
  addPackageToCart,
  receiveProduct,
};
