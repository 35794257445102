import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import Button from 'reactstrap/lib/Button';

import { requestWaitingHeartBeat } from '@ttstr/api/heartbeat';
import { useMounted } from '@ttstr/utils';
import LoadingSpinner from '../Loading/LoadingSpinner';

function getTimeRemaining(t: number) {
  if (t < 0) return '00:00:00';

  const seconds = Math.floor(t % 60);
  const minutes = Math.floor((t / 60) % 60);
  const hours = Math.floor((t / 60 / 60) % 24);
  const days = Math.floor(t / 60 / 60 / 24);
  return (
    (days > 0 ? days + ':' : '') +
    (hours < 10 ? '0' : '') +
    hours +
    ':' +
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  );
}

export const G8Manager: React.FC = () => {
  const isMounted = useMounted();
  const { t } = useTranslation();
  const [heartBeatTimeoutId, setHeartBeatTimeoutId] = React.useState<NodeJS.Timeout>(null);
  const [loading, setLoading] = React.useState(true);
  const [initialized, setInitialized] = React.useState(false);
  const [currentPosition, setCurrentPosition] = React.useState<number>();
  const [activateAt, setActivateAt] = React.useState<number>();
  const intervalId = React.useRef<NodeJS.Timeout>();
  const [diff, setDiff] = React.useState<number>();

  React.useEffect(() => {
    clearInterval(intervalId.current);
    if (activateAt) intervalId.current = setInterval(() => setDiff(activateAt - new Date().getTime() / 1000), 1000);
    return () => clearInterval(intervalId.current);
  }, [activateAt]);

  const startWaitingHeartBeat = React.useCallback(async () => {
    clearTimeout(heartBeatTimeoutId);
    const beat = await requestWaitingHeartBeat();

    /* debug output */
    // const beat = {
    //   token: '123',
    //   position: '42',
    //   activate_at: new Date(),
    //   queue_heartbeat: 10000,
    //   message: 'Test Message',
    // };

    let nextBeat = beat.queue_heartbeat;

    if (beat.activate_at) {
      setActivateAt(beat.activate_at.getTime() / 1000);
      const diff = (beat.activate_at.getTime() - new Date().getTime()) / 1000;
      nextBeat = Math.min(diff * 1000, nextBeat);
      setDiff(diff);
    } else {
      setActivateAt(null);
      setDiff(null);
    }

    if (beat.position !== '' && Number(beat.position) > -1) {
      setCurrentPosition(Number(beat.position) + 1);
    } else {
      setCurrentPosition(null);
    }

    if (beat.activate_at || (beat.position !== '' && Number(beat.position) > -1)) {
      setHeartBeatTimeoutId(setTimeout(startWaitingHeartBeat, nextBeat));
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await startWaitingHeartBeat();
        if (isMounted.current) setInitialized(true);
      } finally {
        if (isMounted.current) setLoading(false);
      }
    })();
    return () => {
      clearTimeout(heartBeatTimeoutId);
    };
  }, []);

  if (loading) return <LoadingSpinner />;

  if (!initialized) return <div className="text-danger">{t('G8.ERROR')}</div>;

  if (activateAt && diff > 0) {
    return (
      <div>
        <h1 className="text-center mb-4 g8-countdown">
          <Trans i18nKey="G8.COUNTDOWN">
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <small className="mt-2">{t('G8.COUNTDOWN_START_IN')}</small>
            <span className="g8-time-remaining">{{ remaining: getTimeRemaining(diff) }}</span>
          </Trans>
        </h1>
      </div>
    );
  }

  if (currentPosition) {
    return (
      <div>
        <h1 className="text-center mb-4">
          {t('G8.PLEASE_WAIT_TITLE')}
          <small className="mt-2 mb-4">{t('G8.PLEASE_WAIT_SUBTITLE')}</small>
        </h1>
        <h1>{t('G8.YOUR_CURRENT_POSITION', { position: currentPosition })}</h1>
        <div className="alert alert-danger mt-4 text-uppercase">
          <b>{t('G8.ATTENTION')}</b> {t('G8.DO_NOT_RELOAD_INFO')}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-center mb-4">{t('G8.CONTINUE_TITLE')}</h1>
      <Button tag="a" href="/" size="lg" color="danger">
        {t('G8.CONTINUE_CTA')}
      </Button>
    </div>
  );
};

export default React.memo(G8Manager);
